import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { Typography, styled } from "@mui/material";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

import Fab from "@mui/material/Fab";

import AddIcon from "@mui/icons-material/Add";
import Delete from "@mui/icons-material/Delete";
import Clear from "@mui/icons-material/Clear";
import { DeleteDialog } from "../components/DeleteDialog";

import { Link, Navigate } from "react-router-dom";

import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import Switch from "@mui/material/Switch";

import {
  collection,
  query,
  where,
  or,
  getDocs,
  setDoc,
  doc,
  onSnapshot,
} from "firebase/firestore";
import { prototype } from "tinycolor2";
import { Context } from "context/Wrapper";
import { listenAndFilterTo, useFetchCollectionDocs } from "apis/dataSnapshots";
import { db } from "config/config";

const ListItem = styled(Paper)(({ theme, ...rest }) => {
  return {
    width: "100%",
    padding: theme.spacing(1),
    textAlign: "left",
    marginTop: theme.spacing(2),
    marginBottom: 0,
    color: theme.palette.primary.main,
    background: rest?.userGroupCheck
      ? theme.palette.secondary.secondary
      : theme.palette.secondary.main,
  };
});

export const Projects = (props) => {
  const context = useContext(Context);

  const { groupId, isPro, userId } = context?.state?.userProfile || {};
  const user = context?.state?.userProfile || {},
    { showAllDataFlag } = context?.state || {};

  const contacts = useFetchCollectionDocs({
    collectionName: "contacts",
    conditions:
      userId && groupId && isPro && showAllDataFlag
        ? [or(where("groupId", "==", groupId), where("userId", "==", userId))]
        : userId
          ? [where("userId", "==", userId)]
          : [],
    setContext: context.setState,
  });

  const buildings = useFetchCollectionDocs({
    collectionName: "buildings",
    conditions:
      groupId && isPro && showAllDataFlag
        ? [
            or(
              where("groupId", "==", user?.groupId),
              where("userId", "==", user.userId),
            ),
          ]
        : [where("userId", "==", user.userId)],
  });

  // const { db, user, isPro, allData, groupId } = props;
  const [projects, setProjects] = useState([]);

  const navigate = useNavigate();

  const [deleteOpen, setDeleteOpen] = useState(false);

  const [itemToDelete, setItemToDelete] = useState({
    itemId: "",
    identifier: "",
  });

  const [filteredProjects, setFilteredProjects] = useState([]);

  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    // const q =
    //   groupId && isPro && allData
    //     ? query(
    //         collection(db, "projects"),
    //         or(where("groupId", "==", groupId), where("userId", "==", user.uid))
    //       )
    //     : query(collection(db, "projects"), where("userId", "==", user.uid));

    // const unsubscribe = onSnapshot(q, (querySnapshot) => {
    //   const newprojects = querySnapshot.docs.map((doc) => {
    //     const data = doc.data();

    //     const client = props.contacts.find(
    //       (contact) => contact.id === data.client
    //     );

    //     const applicant = props.contacts.find(
    //       (contact) => contact.id === data.applicant
    //     );

    //     const building = props.buildings.find(
    //       (building) => building.id === data.building
    //     );

    //     return {
    //       ...data,
    //       id: doc.id,
    //       clientString: (client && client.fullName) || "",
    //       applicantString: (applicant && applicant.fullName) || "",
    //       buildingString: (building && building.customerReference) || "",
    //     };
    //   });
    //   setProjects(newprojects);
    // });

    const unsubscribe = listenAndFilterTo({
      setData: (docsData) => {
        const newprojects = docsData.map((data) => {
          const client = contacts.find((contact) => contact.id === data.client);

          const applicant = contacts.find(
            (contact) => contact.id === data.applicant,
          );

          const building = buildings.find(
            (building) => building.id === data.building,
          );

          return {
            ...data,
            clientString: (client && client.fullName) || "",
            applicantString: (applicant && applicant.fullName) || "",
            buildingString: (building && building.customerReference) || "",
          };
        });
        setProjects(newprojects);
      },
      user,
      setContext: context?.setState,
      condition: groupId && isPro && showAllDataFlag,
      collectionName: "projects",
    });

    return () => unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isPro,
    groupId,
    showAllDataFlag,
    JSON.stringify(user),
    contacts,
    buildings,
  ]);
  // }, [props.buildings, props.contacts]);

  const handleInput = (e) => {
    let str = e.target.value;

    setSearchValue(str);

    const newArr = projects
      .filter(
        (item) =>
          (item.customerReference &&
            item.customerReference.toLowerCase().includes(str.toLowerCase())) ||
          (item.clientString &&
            item.clientString.toLowerCase().includes(str.toLowerCase())) ||
          (item.applicantString &&
            item.applicantString.toLowerCase().includes(str.toLowerCase())) ||
          (item.buildingString &&
            item.buildingString.toLowerCase().includes(str.toLowerCase())) ||
          (item.DOBNumber &&
            item.DOBNumber.toLowerCase().includes(str.toLowerCase())),
      )
      .map((item) => {
        let newCustomerReference =
          item.customerReference &&
          item.customerReference.replace(
            new RegExp(str, "gi"),
            (match) =>
              `<mark style="background: #2769AA; color: white;">${match}</mark>`,
          );
        let newClientString =
          item.clientString &&
          item.clientString.replace(
            new RegExp(str, "gi"),
            (match) =>
              `<mark style="background: #2769AA; color: white;">${match}</mark>`,
          );
        let newApplicantString =
          item.applicantString &&
          item.applicantString.replace(
            new RegExp(str, "gi"),
            (match) =>
              `<mark style="background: #2769AA; color: white;">${match}</mark>`,
          );
        let newBuildingString =
          item.buildingString &&
          item.buildingString.replace(
            new RegExp(str, "gi"),
            (match) =>
              `<mark style="background: #2769AA; color: white;">${match}</mark>`,
          );
        let newDOBNumber =
          item.DOBNumber &&
          item.DOBNumber.replace(
            new RegExp(str, "gi"),
            (match) =>
              `<mark style="background: #2769AA; color: white;">${match}</mark>`,
          );

        return {
          ...item,
          customerReference: newCustomerReference,
          clientString: newClientString,
          applicantString: newApplicantString,
          buildingString: newBuildingString,
          DOBNumber: newDOBNumber,
        };
      });

    setFilteredProjects(newArr);
  };

  const createMarkup = (html) => {
    return { __html: html };
  };

  const handleTrackChange = async (event, id) => {
    const thisProject = doc(db, "projects", id);
    setDoc(thisProject, { track: event.target.checked }, { merge: true }).then(
      () => {
        console.log("updated");
      },
    );
  };

  const handleDelete = (id, identifier) => {
    setItemToDelete({ itemId: id, identifier: identifier });
    setDeleteOpen(true);
  };

  const confirmDelete = async () => {
    db.collection("projects").doc(itemToDelete.itemId).delete();
    setItemToDelete({ itemId: "", identifier: "" });
    setDeleteOpen(false);
    navigate("/projects");
  };

  const projectCollection =
    searchValue.length > 0 ? filteredProjects : projects;

  return (
    <>
      <Fade in={true}>
        <div>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={8} style={{ position: "sticky" }}>
              <Typography align="left" variant="h4">
                JOBS
              </Typography>
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              <Paper
                component="form"
                onSubmit={(e) => e.preventDefault()}
                sx={{
                  p: "2px 4px",
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  marginLeft: "auto",
                }}
              >
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Search"
                  value={searchValue}
                  inputProps={{ "aria-label": "search" }}
                  onInput={(e) => handleInput(e)}
                />
                {searchValue.length > 0 ? (
                  <IconButton
                    sx={{ p: "10px" }}
                    aria-label="search"
                    onClick={() => setSearchValue("")}
                  >
                    <Clear />
                  </IconButton>
                ) : (
                  <IconButton sx={{ p: "10px" }} aria-label="clear">
                    <SearchIcon />
                  </IconButton>
                )}
              </Paper>
            </Grid>
          </Grid>

          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
            textAlign={"left"}
            padding={"1rem 1rem 0 1rem"}
            sx={{ display: { xs: "none", md: "flex" } }}
          >
            <Grid item xs={12} md={2} lg={2}>
              <span>Job ID</span>
            </Grid>
            <Grid item xs={12} md={2} lg={2}>
              <span>Building</span>
            </Grid>
            <Grid item xs={12} md={2} lg={2}>
              <span>Client</span>
            </Grid>
            <Grid item xs={12} md={2} lg={2}>
              <span>Applicant</span>
            </Grid>
            <Grid item xs={12} md={3} lg={3}>
              <span>DOB Ref.</span>
            </Grid>
            {/*           <Grid item xs={12} md={1} lg={1}>
              <span>Watch?</span>
            </Grid> */}
            <Grid item xs={12} md={1} lg={1}>
              <span>Delete</span>
            </Grid>
          </Grid>

          {projectCollection.map((project) => (
            <ListItem
              userGroupCheck={
                project?.userId !== context?.state?.userProfile?.userId &&
                project?.groupId === context?.state?.userProfile?.groupId
              }
            >
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
              >
                <Grid item xs={12} md={2} lg={2}>
                  <Button
                    variant="contained"
                    style={{ width: "100%", justifyContent: "flex-start" }}
                    color={"primary"}
                    component={Link}
                    to={`/projects/details/${project.id}`}
                  >
                    <span
                      dangerouslySetInnerHTML={createMarkup(
                        project.customerReference,
                      )}
                    />
                  </Button>
                </Grid>
                <Grid item xs={12} md={2} lg={2}>
                  <span
                    dangerouslySetInnerHTML={createMarkup(
                      project.buildingString,
                    )}
                  />
                </Grid>

                <Grid item xs={12} md={2} lg={2}>
                  <span
                    dangerouslySetInnerHTML={createMarkup(project.clientString)}
                  />
                </Grid>

                <Grid item xs={12} md={2} lg={2}>
                  <span
                    dangerouslySetInnerHTML={createMarkup(
                      project.applicantString,
                    )}
                  />
                </Grid>

                <Grid item xs={12} md={3} lg={3}>
                  <span
                    dangerouslySetInnerHTML={createMarkup(project.DOBNumber)}
                  />
                </Grid>

                {/*                 <Grid item xs={1} md={1} lg={1}>
                  <Switch
                    checked={project.track}
                    onChange={(e) => handleTrackChange(e, project.id)}
                  />
                </Grid> */}
                <Grid item xs={1} md={1} lg={1}>
                  <IconButton>
                    <Delete
                      onClick={() =>
                        handleDelete(project.id, project.customerReference)
                      }
                    />
                  </IconButton>
                </Grid>
              </Grid>
            </ListItem>
          ))}
          <Box
            sx={{
              position: "fixed",
              bottom: "1rem",
              right: "1rem",
              "& > :not(style)": { m: 1 },
            }}
          >
            {!props.isInactive && (
              <Link to="/projects/new">
                <Fab
                  color="primary"
                  aria-label="add"
                  variant="extended"
                  disabled={props.isInactive}
                >
                  <AddIcon sx={{ mr: 1 }} />
                  New
                </Fab>
              </Link>
            )}
          </Box>
        </div>
      </Fade>
      <DeleteDialog
        open={deleteOpen}
        confirmDelete={confirmDelete}
        setDialogOpen={setDeleteOpen}
        identifier={itemToDelete.identifier}
      />
    </>
  );
};
