import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Typography, styled } from "@mui/material";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import Delete from "@mui/icons-material/Delete";
import Clear from "@mui/icons-material/Clear";
import { DeleteDialog } from "../components/DeleteDialog";
import { Link } from "react-router-dom";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import { setDoc, doc } from "firebase/firestore";
import { Context } from "context/Wrapper";
import { db } from "config/config";
import { listenAndFilterTo } from "apis/dataSnapshots";

const ListItem = styled(Paper)(({ theme, ...rest }) => {
  return {
    width: "100%",
    padding: theme.spacing(1),
    textAlign: "left",
    marginTop: theme.spacing(2),
    marginBottom: 0,
    color: theme.palette.primary.main,
    background: rest?.userGroupCheck
      ? theme.palette.secondary.secondary
      : theme.palette.secondary.main,
  };
});

export const Contacts = (props) => {
  const context = useContext(Context);

  const { groupId, isPro } = context?.state?.userProfile || {};
  const user = context?.state?.userProfile || {},
    { showAllDataFlag } = context?.state || {};

  // const { db, user, groupId, allData, isPro } = props;

  const [contacts, setContacts] = useState([]);

  const navigate = useNavigate();

  const [deleteOpen, setDeleteOpen] = useState(false);

  const [itemToDelete, setItemToDelete] = useState({
    itemId: "",
    identifier: "",
  });

  const [filteredContacts, setFilteredContacts] = useState([]);

  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    // const q =
    //   groupId && isPro && allData
    //     ? query(
    //         collection(db, "contacts"),
    //         or(
    //           where("groupId", "==", groupId),
    //           where("userId", "==", user.uid),
    //         ),
    //       )
    //     : query(collection(db, "contacts"), where("userId", "==", user.uid));

    // const unsubscribe = onSnapshot(q, (querySnapshot) => {
    //   const newcontacts = querySnapshot.docs.map((doc) => {
    //     const data = doc.data();
    //     return { ...data, id: doc.id };
    //   });
    //   setContacts(newcontacts);
    // });

    const unsubscribe = listenAndFilterTo({
      setData: setContacts,
      user,
      setContext: context?.setState,
      condition: groupId && isPro && showAllDataFlag,
      collectionName: "contacts",
    });

    return () => unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isPro,
    groupId,
    showAllDataFlag,
    JSON.stringify(user),
    context?.setState,
  ]);
  // }, []);

  const handleInput = (e) => {
    let str = e.target.value;

    setSearchValue(str);

    const newArr = contacts
      .filter(
        (item) =>
          item.fullName?.toLowerCase().includes(str.toLowerCase()) ||
          item.businessName?.toLowerCase().includes(str.toLowerCase()) ||
          item.phone?.toLowerCase().includes(str.toLowerCase()) ||
          item.email?.toLowerCase().includes(str.toLowerCase()),
      )
      .map((item) => {
        let newFullName =
          item.fullName &&
          item.fullName.replace(
            new RegExp(str, "gi"),
            (match) =>
              `<mark style="background: #2769AA; color: white;">${match}</mark>`,
          );
        let newPhone =
          item.phone &&
          item.phone.replace(
            new RegExp(str, "gi"),
            (match) =>
              `<mark style="background: #2769AA; color: white;">${match}</mark>`,
          );
        let newBusinessName =
          item.businessName &&
          item.businessName.replace(
            new RegExp(str, "gi"),
            (match) =>
              `<mark style="background: #2769AA; color: white;">${match}</mark>`,
          );
        let newEmail =
          item.email &&
          item.email.replace(
            new RegExp(str, "gi"),
            (match) =>
              `<mark style="background: #2769AA; color: white;">${match}</mark>`,
          );
        return {
          ...item,
          fullName: newFullName,
          phone: newPhone,
          businessName: newBusinessName,
          email: newEmail,
        };
      });

    setFilteredContacts(newArr);
  };

  const createMarkup = (html) => {
    return { __html: html };
  };

  const handleTrackChange = async (event, id) => {
    const thisContact = doc(db, "contacts", id);
    setDoc(thisContact, { track: event.target.checked }, { merge: true }).then(
      () => {
        console.log("updated");
      },
    );
  };

  const handleDelete = (id, identifier) => {
    setItemToDelete({ itemId: id, identifier: identifier });
    setDeleteOpen(true);
  };

  const confirmDelete = async () => {
    db.collection("contacts").doc(itemToDelete.itemId).delete();
    setItemToDelete({ itemId: "", identifier: "" });
    setDeleteOpen(false);
    navigate("/contacts");
  };

  const contactCollection =
    searchValue.length > 0 ? filteredContacts : contacts;

  return (
    <>
      <Fade in={true}>
        <div>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={8} style={{ position: "sticky" }}>
              <Typography align="left" variant="h4">
                CONTACTS
              </Typography>
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              <Paper
                component="form"
                onSubmit={(e) => e.preventDefault()}
                sx={{
                  p: "2px 4px",
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  marginLeft: "auto",
                }}
              >
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Search"
                  value={searchValue}
                  inputProps={{ "aria-label": "search" }}
                  onInput={(e) => handleInput(e)}
                />
                {searchValue.length > 0 ? (
                  <IconButton
                    sx={{ p: "10px" }}
                    aria-label="search"
                    onClick={() => setSearchValue("")}
                  >
                    <Clear />
                  </IconButton>
                ) : (
                  <IconButton sx={{ p: "10px" }} aria-label="clear">
                    <SearchIcon />
                  </IconButton>
                )}
              </Paper>
            </Grid>
          </Grid>

          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
            textAlign={"left"}
            padding={"1rem 1rem 0 1rem"}
            sx={{ display: { xs: "none", md: "flex" } }}
          >
            <Grid item xs={12} md={3} lg={3}>
              <span>Name</span>
            </Grid>
            <Grid item xs={12} md={3} lg={3}>
              <span>Business</span>
            </Grid>
            <Grid item xs={12} md={2} lg={2}>
              <span>Phone</span>
            </Grid>
            <Grid item xs={12} md={3} lg={3}>
              <span>Email</span>
            </Grid>
            {/*             <Grid item xs={12} md={1} lg={1}>
              <span>Watch?</span>
            </Grid> */}
            <Grid item xs={12} md={1} lg={1}>
              <span>Delete</span>
            </Grid>
          </Grid>
          {contactCollection.map((contact) => (
            <ListItem
              userGroupCheck={
                contact?.userId !== context?.state?.userProfile?.userId &&
                contact?.groupId === context?.state?.userProfile?.groupId
              }
            >
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
              >
                <Grid item xs={12} md={3} lg={3}>
                  <Button
                    variant="contained"
                    style={{ width: "100%", justifyContent: "flex-start" }}
                    color={"primary"}
                    component={Link}
                    to={`/contacts/details/${contact.id}`}
                  >
                    <span
                      dangerouslySetInnerHTML={createMarkup(contact.fullName)}
                    />
                  </Button>
                </Grid>
                <Grid item xs={12} md={3} lg={3}>
                  <span
                    dangerouslySetInnerHTML={createMarkup(contact.businessName)}
                  />
                </Grid>
                <Grid item xs={12} md={2} lg={2}>
                  <span dangerouslySetInnerHTML={createMarkup(contact.phone)} />
                </Grid>
                <Grid item xs={12} md={3} lg={3}>
                  <span dangerouslySetInnerHTML={createMarkup(contact.email)} />
                </Grid>
                {/*                 <Grid item xs={1} md={1} lg={1}>
                  <Switch
                    checked={contact.track}
                    onChange={(e) => handleTrackChange(e, contact.id)}
                  />
                </Grid> */}
                <Grid item xs={1} md={1} lg={1}>
                  <IconButton>
                    <Delete
                      onClick={() => handleDelete(contact.id, contact.fullName)}
                    />
                  </IconButton>
                </Grid>
              </Grid>
            </ListItem>
          ))}
          <Box
            sx={{
              position: "fixed",
              bottom: "1rem",
              right: "1rem",
              "& > :not(style)": { m: 1 },
            }}
          >
            {!props.isInactive && (
              <Link to="/contacts/new">
                <Fab
                  color="primary"
                  aria-label="add"
                  variant="extended"
                  disabled={props.isInactive}
                >
                  <AddIcon sx={{ mr: 1 }} />
                  New
                </Fab>
              </Link>
            )}
          </Box>
        </div>
      </Fade>
      <DeleteDialog
        open={deleteOpen}
        confirmDelete={confirmDelete}
        setDialogOpen={setDeleteOpen}
        identifier={itemToDelete.identifier}
      />
    </>
  );
};
