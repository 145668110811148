import { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  FormControl,
  InputLabel,
  MenuItem,
  NativeSelect,
  Select,
  TextField,
} from "@mui/material";

const DocumentDataDialog = ({
  open,
  handleDocumentData,
  documents,
  setDialogOpen,
  documentTypes,
}) => {
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setDialogOpen(false);
  };

  const selectedDocument = documents?.[open] || {};

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const dataObj = {
      ...selectedDocument,
      ...Object.fromEntries(data.entries()),
    };

    if (
      open &&
      open !== true &&
      documentTypes.find((type) => type.id === selectedDocument.documentType)
    )
      dataObj.documentType = selectedDocument?.documentType;

    const typeName = documentTypes.find(
        (type) => type.id === dataObj.documentType,
      )?.name,
      fileUrl =
        dataObj?.documentTypeFileUrl ||
        documentTypes.find((type) => type.id === dataObj.documentType)?.fileUrl;

    if (typeName?.length > 0) dataObj.documentTypeName = typeName;
    if (fileUrl?.length > 0) dataObj.documentTypeFileUrl = fileUrl;

    let documentsTmp = documents;
    if (open === true) documentsTmp = [...documents, dataObj];
    else documentsTmp[open] = dataObj;

    handleDocumentData(documentsTmp);
    setLoading(false);
    e.target.reset();
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle id="alert-dialog-title">
          {open?.length ? "Edit" : "Add"} Document
        </DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            width: "400px",
            paddingBlock: "10px !important",
          }}
        >
          <FormControl fullWidth>
            <InputLabel id="documentType">Document type</InputLabel>
            <Select
              labelId="documentType"
              name="documentType"
              label="Document Type"
              disabled={open?.length ? true : false}
              defaultValue={selectedDocument?.documentType || ""}
              required
            >
              {/* <MenuItem value="add_new">Add new</MenuItem> */}
              {documentTypes.map((type) => (
                <MenuItem value={type?.id}>{type?.name}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            label={"Date Sent"}
            InputLabelProps={{ shrink: true }}
            type="date"
            name="dateSent"
            defaultValue={selectedDocument?.dateSent}
            onChange={(event) => {
              // handleChangeNewFormNote(event);
            }}
            fullWidth
          />
          <TextField
            label={"Recipient"}
            type="text"
            name="recipient"
            defaultValue={selectedDocument?.recipient}
            onChange={(event) => {
              // handleChangeNewFormNote(event);
            }}
            fullWidth
          />
          <TextField
            label={"Date Received"}
            InputLabelProps={{ shrink: true }}
            type="date"
            name="dateReceived"
            defaultValue={selectedDocument?.dateReceived}
            onChange={(event) => {
              // handleChangeNewFormNote(event);
            }}
            fullWidth
          />
          <TextField
            label={"Comments"}
            multiline
            name="comments"
            defaultValue={selectedDocument?.comments}
            onChange={(event) => {
              // handleChangeNewFormNote(event);
            }}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button type="button" variant="contained" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            type="submit"
            // onClick={confirmDelete}
            variant="contained"
            color="secondary"
            disabled={loading}
          >
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DocumentDataDialog;
