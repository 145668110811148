import React, { useEffect } from "react";

import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";

import Grid from "@mui/material/Grid";

import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Slide from "@mui/material/Slide";
import { useTheme } from "@mui/material/styles";

import { StarBorder as StarIcon, TireRepair } from "@mui/icons-material";

import X from "./x";
import P from "./p";
import D from "./d";
import T from "./t";
import R from "./r";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © 9503765 Canada Inc. 2023 – "}
      {new Date().getFullYear()}
    </Typography>
  );
}

const BuyNow = (props) => {
  const theme = useTheme();

  const checkout = (email, product) => {
    props.toggleBuyNow();
    window.Paddle.Checkout.open({
      settings: {
        theme: "light",
      },
      items: [
        {
          priceId: product,
          quantity: 1,
        },
      ],
      customer: {
        email: email,
      },
    });
  };

  const handleClose = () => {
    props.toggleBuyNow();
  };

  const [tiers, setTiers] = React.useState([]);

  useEffect(() => {
    checkIfPro();
    //  setPro(SubscriptionDispatch); // REMOVE!! IMPORTANT!! TODO!!
  }, []);

  const checkIfPro = () => {
    // Send email to check.php.
    // check.php must get the list of all users from Paddle, then see if current user is among Pro users.

    const URL =
      process.env.NODE_ENV === "production" ? `/prices.php` : `/api/prices.php`;

    const PARAMS = {
      method: "GET",
      cache: "default",
    };

    const isJson = (str) => {
      try {
        JSON.parse(str);
      } catch (e) {
        return false;
      }
      return true;
    };

    fetch(URL, PARAMS)
      .then((res) => res.text())
      .then((response) => {
        if (response.indexOf("No customer") === -1 && isJson(response)) {
          let res = JSON.parse(response);
          setTiers(
            res
              .filter((tier) => tier.custom_data.Display === "true")
              .sort((a, b) =>
                a.custom_data.Order > b.custom_data.Order ? 1 : -1,
              ),
          );
        }
      })
      .catch((error) => console.error("Error:", error));
  };

  return (
    <Dialog
      maxWidth={"xl"}
      open={props.open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar
        position="static"
        color="secondary"
        elevation={0}
        sx={{
          borderBottom: `1px solid ${theme.palette.divider}`,
        }}
      >
        <Toolbar
          sx={{
            flexWrap: "wrap",
          }}
        >
          <Typography
            variant="h6"
            color="inherit"
            noWrap
            sx={{ display: { xs: "none", sm: "inline" } }}
          >
            Trial mode
          </Typography>

          <Button
            color="primary"
            variant="contained"
            onClick={handleClose}
            sx={{
              textTransform: "uppercase",
              position: "absolute",
              right: "20px",
            }}
          >
            cancel
          </Button>
        </Toolbar>
      </AppBar>
      {/* Hero unit */}

      {/* End hero unit */}
      <Container maxWidth="md" component="main">
        ´
        <Card>
          <CardHeader
            title="Free Trial"
            titleTypographyProps={{ align: "center" }}
            subheaderTypographyProps={{
              align: "center",
              sx: { color: "white" },
            }}
            sx={{
              backgroundColor: theme.palette.primary.main,
              color: "white",
              fontWeight: 700,
              textTransform: "uppercase",
            }}
          />
          <CardContent
            sx={{
              padding: theme.spacing(2, 0, 2),
              textAlign: "center",
            }}
          >
            <div>
              <Typography
                component="h5"
                variant="h5"
                color="textPrimary"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "baseline",
                  marginBottom: theme.spacing(2),
                }}
              >
                You are currently in Trial mode! Feel free to look around and
                try out XPDTR. Be sure to check out the subscription plans on
                our website. ‌
              </Typography>
            </div>
          </CardContent>
          <CardActions>
            <Button fullWidth variant="contained" href="https://www.xpdtr.com">
              Go to XPDTR.com
            </Button>
          </CardActions>
        </Card>
      </Container>

      <Container maxWidth="md" component="footer">
        <Box mt={5} sx={{ marginBottom: "10px" }}>
          <Copyright />
        </Box>
      </Container>
    </Dialog>
  );
};

export default BuyNow;
