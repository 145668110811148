import { Snackbar } from "@mui/material";
import { db } from "config/config";
import {
  collection,
  getDocs,
  onSnapshot,
  or,
  query,
  where,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { json } from "react-router-dom";

export const listenAndFilterTo = (props) => {
  const { setData, user, condition, setContext, collectionName } = props;
  if (!(user?.groupId?.length > 0 && user?.userId?.length > 0)) {
    setData([]);
    return () => {};
  }

  try {
    const q = condition
      ? query(
          collection(db, collectionName),
          or(
            where("groupId", "==", user?.groupId),
            where("userId", "==", user.userId),
          ),
        )
      : query(
          collection(db, collectionName),
          where("userId", "==", user.userId),
        );

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const newDocs = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      setData(newDocs);
    });

    return unsubscribe;
  } catch (err) {
    console.log(err);
    setData([]);

    setContext({ snackbar: <Snackbar open={true} message={err?.message} /> });

    setTimeout(() => {
      setContext({ snackbar: null });
    }, 4000);

    return () => {};
  }
};

export const useFetchCollectionDocs = ({
  collectionName,
  conditions = [],
  setContext = () => {},
}) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        if (conditions?.length === 0) return;

        const data = await getDocs(
          query(collection(db, collectionName), ...conditions),
        );

        if (data.empty) return;

        return setData(
          data.docs?.map((doc) => ({
            ...doc?.data(),
            id: doc.id,
          })) || [],
        );
      } catch (error) {
        setContext({
          snackbar: <Snackbar open={true} message={error?.message} />,
        });

        setTimeout(() => {
          setContext({ snackbar: null });
        }, 4000);

        console.log("error", error);
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectionName, JSON.stringify(conditions)]);

  return data;
};
