import {
  Box,
  Fab,
  IconButton,
  Grid,
  Typography,
  Paper,
  InputBase,
  CircularProgress,
} from "@mui/material";
import { Delete, Clear } from "@mui/icons-material";
import Table from "./Table";
import DocumentDataDialog from "./DocumentDataDialog";
import { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import { getDocData, getDocumentTypes } from "apis/documents";
import AddTypeDialog from "./AddTypeDialog";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "config/config";
import Docxtemplater from "docxtemplater";
import PizZip from "pizzip";
import PizZipUtils from "pizzip/utils/index.js";
import { saveAs } from "file-saver";
import expressionParser from "docxtemplater/expressions";

let searchTimeout = null;

function loadFile(url, callback) {
  return PizZipUtils.getBinaryContent(url, callback);
}
const Documents = ({
  documents,
  projectId,
  onSubmit,
  getProject,
  projectData,
  isInactive,
}) => {
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [addTypeDialogOpen, setAddTypeDialogOpen] = useState(false);
  // const [newNote, setNewNote] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [documentTypes, setDocumentTypes] = useState([]);
  // const [selectedDocument, setSelectedDocument] = useState({});
  const [filteredDocument, setFilteredDocument] = useState([]);
  const [loadingAnimation, setLoadingAnimation] = useState(false);

  useEffect(() => {
    (async () => {
      setLoadingAnimation(true);
      await getDocumentTypes(setDocumentTypes);
      setLoadingAnimation(false);
    })();
  }, []);

  useEffect(() => {
    if (searchTimeout !== null) clearTimeout(searchTimeout);

    searchTimeout = setTimeout(() => {
      setFilteredDocument(
        documents?.filter(
          (item) =>
            item?.name?.toLowerCase()?.includes(searchValue?.toLowerCase()) ||
            item?.documentTypeName
              ?.toLowerCase()
              ?.includes(searchValue?.toLowerCase()) ||
            item?.dateSent
              ?.toLowerCase()
              ?.includes(searchValue?.toLowerCase()) ||
            item?.recipient
              ?.toLowerCase()
              ?.includes(searchValue?.toLowerCase()) ||
            item?.dateReceived
              ?.toLowerCase()
              ?.includes(searchValue?.toLowerCase()) ||
            item?.comments?.toLowerCase()?.includes(searchValue?.toLowerCase()),
        ),
        // ?.map((item) => ({
        //   ...item,
        //   name: item?.name?.replace(
        //     searchValue,
        //     (match) =>
        //       `<mark style="background: #2769AA; color: white;">${match}</mark>`
        //   ),
        //   dateSent: item?.dateSent?.replace(
        //     searchValue,
        //     (match) =>
        //       `<mark style="background: #2769AA; color: white;">${match}</mark>`
        //   ),
        //   recipient: item?.recipient?.replace(
        //     searchValue,
        //     (match) =>
        //       `<mark style="background: #2769AA; color: white;">${match}</mark>`
        //   ),
        //   dateReceived: item?.dateReceived?.replace(
        //     searchValue,
        //     (match) =>
        //       `<mark style="background: #2769AA; color: white;">${match}</mark>`
        //   ),
        //   comments: item?.comments?.replace(
        //     searchValue,
        //     (match) =>
        //       `<mark style="background: #2769AA; color: white;">${match}</mark>`
        //   ),
        // }))
      );
    }, 800);
  }, [documents, searchValue]);

  // var decodedDocuments =
  //   documents && documents.length > 0 ? JSON.parse(documents) : [];

  const confirmDelete = async () => {
    // db.collection("filings").doc(itemToDelete.itemId).delete();
    // setItemToDelete({ itemId: "", identifier: "" });
    // setAddDialogOpen(false);
  };

  const generateDocument = async (fileUrl, fileInfo, cb) => {
    const client =
      projectData.client?.length > 0
        ? await getDocData(projectData.client, "contacts")
        : {};
    const building =
      projectData.building?.length > 0
        ? await getDocData(projectData.building, "buildings")
        : {};
    loadFile(fileUrl, async function (error, content) {
      if (error) {
        throw error;
      }

      try {
        const zip = new PizZip(content);

        const doc = new Docxtemplater(zip, {
          paragraphLoop: true,
          linebreaks: true,
          parser: expressionParser,
        });

        const todaysDate = new Date().toLocaleDateString("en-US", {
          year: "numeric",
          month: "short",
          day: "2-digit",
        });
        doc.render({
          jobID: projectData?.id || "--",
          clientName: `${client?.firstName || ""} ${client?.lastName || ""}`,
          clientFullName: client?.fullName || "",
          clientCompanyName: client?.businessName || "",
          clientAddress: client?.address1 || "",
          clientCity: client?.city || "",
          clientState: client?.state || "",
          clientZIP: client?.ZIP || "",
          projectLocation:
            `${building?.city || ""} ${building?.street || ""} ${building?.state || ""}` ||
            "",
          todaysDate,

          // floors:client?.,
          // tenantBusinessName, //commented
          // tenantAddress, //commented
          // tenantCity, //commented
          // tenantState, //commented
          // tenantZIP, //commented

          // projectDescription,
          // totalFee,
        });

        const out = doc.getZip().generate({
          type: "blob",
          mimeType:
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        }); //Output the document using Data-URI

        saveAs(out, `${fileInfo?.customMetadata?.name}`);
      } catch (error) {
        // console.log("error", error);
        cb();
      }
    });
  };

  const handleDocumentData = async (documents) => {
    setLoadingAnimation(true);
    const projectRef = doc(db, "projects", projectId);
    await updateDoc(projectRef, {
      documents,
    });
    setLoadingAnimation(false);
    return getProject();
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={8} style={{ position: "sticky" }}>
          <Typography align="left" variant="h4">
            Documents
          </Typography>
        </Grid>

        <Grid item xs={12} md={6} lg={4}>
          <Paper
            component="form"
            onSubmit={(e) => e.preventDefault()}
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
              width: "100%",
              marginLeft: "auto",
            }}
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search"
              value={searchValue}
              inputProps={{ "aria-label": "search" }}
              onInput={(e) => setSearchValue(e.target.value)}
            />
            {searchValue.length > 0 ? (
              <IconButton
                sx={{ p: "10px" }}
                aria-label="search"
                onClick={() => setSearchValue("")}
              >
                <Clear />
              </IconButton>
            ) : (
              <IconButton sx={{ p: "10px" }} aria-label="clear">
                <SearchIcon />
              </IconButton>
            )}
          </Paper>
        </Grid>
      </Grid>

      <Table
        items={filteredDocument}
        documentTypes={documentTypes}
        handleDocumentData={handleDocumentData}
        setAddDialogOpen={setAddDialogOpen}
        setLoadingAnimation={setLoadingAnimation}
        generateDocument={generateDocument}
      />

      <Box
        sx={{
          position: "fixed",
          bottom: "1rem",
          right: "1rem",
          "& > :not(style)": { m: 1 },
        }}
      >
        {/* <Link to="/projects/new"> */}
        {!isInactive && (
          <Fab
            color="primary"
            aria-label="add"
            variant="extended"
            onClick={() => setAddDialogOpen(true)}
          >
            <AddIcon sx={{ mr: 1 }} />
            New
          </Fab>
        )}
      </Box>
      <CircularProgress
        {...loadingAnimation}
        style={{
          display: loadingAnimation ? "" : "none",
          position: "fixed",
          right: "10%",
          bottom: "10%",
          ...loadingAnimation?.style,
        }}
      />
      <DocumentDataDialog
        open={addDialogOpen}
        handleDocumentData={handleDocumentData}
        documents={documents}
        setDialogOpen={setAddDialogOpen}
        documentTypes={documentTypes}
      />
      <AddTypeDialog
        open={addTypeDialogOpen}
        reloadData={() => getDocumentTypes(setDocumentTypes)}
        setDialogOpen={setAddTypeDialogOpen}
        documentTypes={documentTypes}
      />
    </>
  );
};

export default Documents;
