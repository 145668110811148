import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { Typography, styled } from "@mui/material";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

import Fab from "@mui/material/Fab";

import AddIcon from "@mui/icons-material/Add";
import Delete from "@mui/icons-material/Delete";
import Clear from "@mui/icons-material/Clear";
import { DeleteDialog } from "../components/DeleteDialog";

import { Link, Navigate } from "react-router-dom";

import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";

import {
  collection,
  query,
  setDoc,
  doc,
  onSnapshot,
  or,
  where,
} from "firebase/firestore";
import { Context } from "context/Wrapper";
import { useFetchCollectionDocs } from "apis/dataSnapshots";
import { db } from "config/config";

const ListItem = styled(Paper)(({ theme, ...rest }) => {
  return {
    width: "100%",
    padding: theme.spacing(1),
    textAlign: "left",
    marginTop: theme.spacing(2),
    marginBottom: 0,
    color: theme.palette.primary.main,
    background: theme.palette.secondary.main,
  };
});

export const Profiles = (props) => {
  const context = useContext(Context);

  const { groupId, isPro } = context?.state?.userProfile || {};
  const user = context?.state?.userProfile || {},
    { showAllDataFlag } = context?.state || {};

  const profiles = useFetchCollectionDocs({
    collectionName: "profiles",
    conditions: [true],
  });

  const [userProfiles, setuserProfiles] = useState([]);

  const navigate = useNavigate();

  const [deleteOpen, setDeleteOpen] = useState(false);

  const [itemToDelete, setItemToDelete] = useState({
    itemId: "",
    identifier: "",
  });

  const [filtereduserProfiles, setFiltereduserProfiles] = useState([]);

  const [searchValue, setSearchValue] = useState("");

  // useEffect(() => {
  //   const q = query(collection(db, "profiles"));

  //   const unsubscribe = onSnapshot(q, (querySnapshot) => {
  //     const newuserProfiles = querySnapshot.docs.map((doc) => {
  //       const data = doc.data();
  //       return { ...data, id: doc.id };
  //     });
  //     setuserProfiles(newuserProfiles);
  //   });

  //   return () => unsubscribe();
  // }, []);

  const handleInput = (e) => {
    let str = e.target.value;

    setSearchValue(str);

    const newArr = profiles
      .filter(
        (item) =>
          item.fullName?.toLowerCase().includes(str.toLowerCase()) ||
          item.businessName?.toLowerCase().includes(str.toLowerCase()) ||
          item.phone?.toLowerCase().includes(str.toLowerCase()) ||
          item.email?.toLowerCase().includes(str.toLowerCase()),
      )
      .map((item) => {
        let newFullName =
          item.fullName &&
          item.fullName.replace(
            new RegExp(str, "gi"),
            (match) =>
              `<mark style="background: #2769AA; color: white;">${match}</mark>`,
          );
        let newPhone =
          item.phone &&
          item.phone.replace(
            new RegExp(str, "gi"),
            (match) =>
              `<mark style="background: #2769AA; color: white;">${match}</mark>`,
          );
        let newBusinessName =
          item.businessName &&
          item.businessName.replace(
            new RegExp(str, "gi"),
            (match) =>
              `<mark style="background: #2769AA; color: white;">${match}</mark>`,
          );
        let newEmail =
          item.email &&
          item.email.replace(
            new RegExp(str, "gi"),
            (match) =>
              `<mark style="background: #2769AA; color: white;">${match}</mark>`,
          );
        return {
          ...item,
          fullName: newFullName,
          phone: newPhone,
          businessName: newBusinessName,
          email: newEmail,
        };
      });

    setFiltereduserProfiles(newArr);
  };

  const createMarkup = (html) => {
    return { __html: html };
  };

  const handleTrackChange = async (event, id) => {
    const thisContact = doc(db, "userProfiles", id);
    setDoc(thisContact, { track: event.target.checked }, { merge: true }).then(
      () => {
        console.log("updated");
      },
    );
  };

  const handleDelete = (id, identifier) => {
    setItemToDelete({ itemId: id, identifier: identifier });
    setDeleteOpen(true);
  };

  const confirmDelete = async () => {
    db.collection("userProfiles").doc(itemToDelete.itemId).delete();
    setItemToDelete({ itemId: "", identifier: "" });
    setDeleteOpen(false);
    navigate("/userProfiles");
  };

  const contactCollection =
    searchValue.length > 0 ? filtereduserProfiles : profiles;

  if (
    user.uid !== process.env.REACT_APP_DEV_FIREBASE_ADMIN_ID &&
    user.uid !== process.env.REACT_APP_PROD_FIREBASE_ADMIN_ID &&
    user.uid !== process.env.REACT_APP_PROD_FIREBASE_ADMIN_ID_ALT &&
    user.uid !== process.env.REACT_APP_DEV_FIREBASE_ADMIN_ID_ALT &&
    user.uid !== process.env.REACT_APP_DEV_FIREBASE_ADMIN_ID_ALT2
  ) {
    return "You are not authorized to view this page.";
  }

  return (
    <>
      <Fade in={true}>
        <div>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={8} style={{ position: "sticky" }}>
              <Typography align="left" variant="h4">
                User Profiles
              </Typography>
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              <Paper
                component="form"
                onSubmit={(e) => e.preventDefault()}
                sx={{
                  p: "2px 4px",
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  marginLeft: "auto",
                }}
              >
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Search"
                  value={searchValue}
                  inputProps={{ "aria-label": "search" }}
                  onInput={(e) => handleInput(e)}
                />
                {searchValue.length > 0 ? (
                  <IconButton
                    sx={{ p: "10px" }}
                    aria-label="search"
                    onClick={() => setSearchValue("")}
                  >
                    <Clear />
                  </IconButton>
                ) : (
                  <IconButton sx={{ p: "10px" }} aria-label="clear">
                    <SearchIcon />
                  </IconButton>
                )}
              </Paper>
            </Grid>
          </Grid>

          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
            textAlign={"left"}
            padding={"1rem 1rem 0 1rem"}
            sx={{ display: { xs: "none", md: "flex" } }}
          >
            <Grid item xs={12} md={3} lg={3}>
              <span>Email</span>
            </Grid>
            <Grid item xs={12} md={3} lg={3}>
              <span>Subscription</span>
            </Grid>
            <Grid item xs={12} md={2} lg={2}>
              <span>Subscription Type</span>
            </Grid>
            <Grid item xs={12} md={3} lg={3}>
              <span>Expiration Date</span>
            </Grid>

            <Grid item xs={12} md={1} lg={1}>
              <span>Delete</span>
            </Grid>
          </Grid>

          {contactCollection.map((contact) => (
            <ListItem>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
              >
                <Grid item xs={12} md={3} lg={3}>
                  <Button
                    variant="contained"
                    style={{ width: "100%", justifyContent: "flex-start" }}
                    color={"primary"}
                    component={Link}
                    to={`/userProfiles/details/${contact.id}`}
                  >
                    <span
                      dangerouslySetInnerHTML={createMarkup(contact.email)}
                    />
                  </Button>
                </Grid>
                <Grid item xs={12} md={3} lg={3}>
                  <span
                    dangerouslySetInnerHTML={createMarkup(contact.subscription)}
                  />
                </Grid>
                <Grid item xs={12} md={2} lg={2}>
                  <span
                    dangerouslySetInnerHTML={createMarkup(
                      contact.subscriptionType,
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={3} lg={3}>
                  <span
                    dangerouslySetInnerHTML={createMarkup(
                      contact.expirationDate,
                    )}
                  />
                </Grid>
                {/*                 <Grid item xs={1} md={1} lg={1}>
                  <Switch
                    checked={contact.track}
                    onChange={(e) => handleTrackChange(e, contact.id)}
                  />
                </Grid> */}
                <Grid item xs={1} md={1} lg={1}>
                  <IconButton>
                    <Delete
                      onClick={() => handleDelete(contact.id, contact.email)}
                    />
                  </IconButton>
                </Grid>
              </Grid>
            </ListItem>
          ))}
          <Box
            sx={{
              position: "fixed",
              bottom: "1rem",
              right: "1rem",
              "& > :not(style)": { m: 1 },
            }}
          >
            {!props.isInactive && (
              <Link to="/userProfiles/new">
                <Fab
                  color="primary"
                  aria-label="add"
                  variant="extended"
                  disabled={props.isInactive}
                >
                  <AddIcon sx={{ mr: 1 }} />
                  New
                </Fab>
              </Link>
            )}
          </Box>
        </div>
      </Fade>
      <DeleteDialog
        open={deleteOpen}
        confirmDelete={confirmDelete}
        setDialogOpen={setDeleteOpen}
        identifier={itemToDelete.identifier}
      />
    </>
  );
};
