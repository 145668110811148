import React, { useRef, useState } from "react";

import { Box, Link, Fab } from "@mui/material/";
import Clear from "@mui/icons-material/Clear";
import Save from "@mui/icons-material/Save";

import {
  PdfViewerComponent,
  Toolbar,
  Magnification,
  Navigation,
  LinkAnnotation,
  BookmarkView,
  ThumbnailView,
  Print,
  TextSelection,
  Annotation,
  TextSearch,
  Inject,
  FormFields,
  DisplayMode,
} from "@syncfusion/ej2-react-pdfviewer";

import "../styles/editorOverride.css";

const PDFViewer = ({
  formToView,
  handleSave,
  handleCloseViewer,
  setLoading,
  isNewForm,
  autoFillDetails,
  isInactive,
}) => {
  const childRef = useRef();

  const [fieldArray, setFieldArray] = useState();

  // const isNewForm = true;

  const [pageNumber, setPageNumber] = useState(0);

  const baseUrl = `${window.location.protocol}//${window.location.host}/${process.env.PUBLIC_URL}`;

  function extractKeyValuePairs(arr) {
    const result = [];

    arr.forEach((item) => {
      if (item.details) {
        const prefix = item.prefix;
        const details = item.details;

        for (const key in details) {
          if (details.hasOwnProperty(key)) {
            const keyValue = `${prefix}${key}`;
            const value = details[key];
            result.push({ name: keyValue, value: value, type: "Textbox" });
          }
        }
      }
    });

    return result;
  }

  const onHandleSave = () => {
    setLoading(true);

    var viewer = childRef.current;

    var formFields = viewer.retrieveFormFields();

    viewer.exportFormFieldsAsObject().then(function (formFields) {
      viewer.exportAnnotationsAsObject().then(function (annotations) {
        console.log(formFields, annotations);
      });
    });

    /*  if (viewer.annotationCollection.length > 0) { */
    viewer.exportAnnotationsAsObject().then(function (value) {
      handleSave(JSON.stringify(formFields), value, formToView.formId);
    });
    /*   } else {
      handleSave(JSON.stringify(formFields), "", formToView.formId);
    } */
  };

  const onDocumentLoaded = () => {
    const pdfViewerInstance = childRef.current;

    const formContent = formToView.formContent
      ? JSON.parse(JSON.parse(formToView.formContent))
      : "";

    const newFieldArray = formContent
      ? formContent?.map((field) => ({
          ...field,
          filled: false,
        }))
      : null;

    if (isNewForm) {
      const autoFillFields = extractKeyValuePairs(autoFillDetails);

      autoFillFields.forEach((item) => {
        let fieldsToFill = document.getElementsByName(item.name);

        fieldsToFill.forEach((field) => {
          if (field !== undefined) {
            field.value = item.value;
            pdfViewerInstance.updateFormFieldsValue(field);
          }
        });
      });
    }

    if (
      formToView.formAnnotations &&
      formToView.formAnnotations !== '""' &&
      formToView.formAnnotations !== ""
    ) {
      const annotations = JSON.parse(JSON.parse(formToView.formAnnotations));
      annotations && pdfViewerInstance.importAnnotation(annotations);
    }
    if (newFieldArray) {
      newFieldArray.forEach((item) => {
        if (document.getElementById(item.id) !== null && !item.filled) {
          if (item.type === "CheckBox" && item.isChecked) {
            item.value = true;
            pdfViewerInstance.updateFormFieldsValue(item);
          }
          if (item.type === "RadioButton" && item.isSelected) {
            item.value = true;
            pdfViewerInstance.updateFormFieldsValue(item);
          }

          if (item.type === "Textbox" && item.value !== "") {
            pdfViewerInstance.updateFormFieldsValue(item);
          }
          item.filled = true;
        }
      });
    }
    setFieldArray(newFieldArray);
  };

  const onPageChange = () => {
    const pdfViewerInstance = childRef.current;

    if (isNewForm) {
      const autoFillFields = extractKeyValuePairs(autoFillDetails);

      autoFillFields.forEach((item) => {
        let fieldsToFill = document.getElementsByName(item.name);

        fieldsToFill.forEach((field) => {
          if (field !== undefined) {
            field.value = item.value;
            pdfViewerInstance.updateFormFieldsValue(field);
          }
        });
      });
    }

    if (pdfViewerInstance.currentPageNumber > pageNumber) {
      if (fieldArray) {
        fieldArray.forEach((item) => {
          if (document.getElementById(item.id) !== null && !item.filled) {
            if (item.type === "CheckBox" && item.isChecked) {
              item.value = true;
              pdfViewerInstance.updateFormFieldsValue(item);
            }
            if (item.type === "RadioButton" && item.isSelected) {
              item.value = true;
              pdfViewerInstance.updateFormFieldsValue(item);
            }

            if (item.type === "Textbox" && item.value !== "") {
              pdfViewerInstance.updateFormFieldsValue(item);
            }
            item.filled = true;
          }
        });
      }
      setPageNumber(pageNumber);
      setFieldArray(fieldArray);
    }
  };

  function printClicked() {
    var viewer = childRef.current;

    console.log(viewer.print);
  }

  const downloadEnd = () => {
    window.setTimeout(onHandleSave(), 100);
  };

  console.log(childRef.current);

  return (
    <div>
      <form id="pdfComponent">
        <PdfViewerComponent
          id="container"
          documentPath={window.location.origin + "/pdfs/" + formToView.fileName}
          resourceUrl="https://cdn.syncfusion.com/ej2/23.1.43/dist/ej2-pdfviewer-lib"
          enablePrint={true}
          style={{ height: "calc(100vh - 240px" }}
          signatureDialogSettings={{
            displayMode:
              DisplayMode.Draw | DisplayMode.Text | DisplayMode.Upload,
            hideSaveSignature: true,
          }}
          documentLoad={onDocumentLoaded}
          pageChange={onPageChange}
          ref={childRef}
          downloadEnd={downloadEnd}
          toolbarSettings={{
            showTooltip: true,
            toolbarItems: [
              "UndoRedoTool",
              "PageNavigationTool",
              "MagnificationTool",
              "PanTool",
              "SelectionTool",
              "CommentTool",
              "AnnotationEditTool",
              "FreeTextAnnotationOption",
              "InkAnnotationOption",
              "ShapeAnnotationOption",
              "StampAnnotation",
              "SignatureOption",
              "SearchOption",
              "PrintOption",
              "DownloadOption",
            ],
            annotationToolbarItems: [
              "HighlightTool",
              "UnderlineTool",
              "StrikethroughTool",
              "ColorEditTool",
              "OpacityEditTool",
              "AnnotationDeleteTool",
              "StampAnnotationTool",
              "HandWrittenSignatureTool",
              "InkAnnotationTool",
              "ShapeTool",
              "CalibrateTool",
              "StrokeColorEditTool",
              "ThicknessEditTool",
              "FreeTextAnnotationTool",
              "FontFamilyAnnotationTool",
              "FontSizeAnnotationTool",
              "FontStylesAnnotationTool",
              "FontAlignAnnotationTool",
              "FontColorAnnotationTool",
              "CommentPanelTool",
            ],
          }}
        >
          <Inject
            services={[
              Toolbar,
              Magnification,
              Navigation,
              Annotation,
              LinkAnnotation,
              BookmarkView,
              ThumbnailView,
              Print,
              TextSelection,
              TextSearch,
              FormFields,
            ]}
          />
        </PdfViewerComponent>
      </form>
      <Box
        sx={{
          position: "fixed",
          bottom: "1rem",
          right: "1rem",
          "& > :not(style)": { m: 1 },
        }}
      >
        {!isInactive && (
          <Fab
            color="primary"
            aria-label="add"
            variant="extended"
            onClick={onHandleSave}
            sx={{ mr: 1 }}
            disabled={isInactive}
          >
            <Save sx={{ mr: 1 }} />
            Save
          </Fab>
        )}
        <Fab
          color="secondary"
          aria-label="add"
          variant="extended"
          onClick={handleCloseViewer}
        >
          <Clear sx={{ mr: 1 }} />
          Close
        </Fab>
        {/*           <Fab
            color="secondary"
            aria-label="add"
            variant="extended"
            onClick={onLogStuff}
          >
            <Clear sx={{ mr: 1 }} />
            logstuff
          </Fab> */}
      </Box>
    </div>
  );
};

export default PDFViewer;
