import { Clear, Delete, Download, Edit, Upload } from "@mui/icons-material";
import {
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  ListItem,
  Snackbar,
} from "@mui/material";
import React, { useState } from "react";
import ConfirmDialog from "components/shared/ConfirmDialog";
import { db, nextPushId } from "config/config";
import { getDownloadURL, getMetadata, getStorage, ref } from "firebase/storage";
import { uploadFile, uploadFileResumable } from "apis/documents";

export default function Table({
  items,
  documentTypes,
  handleDocumentData,
  setAddDialogOpen,
  setLoadingAnimation,
  generateDocument,
}) {
  const [deleteDocumentIndex, setDeleteDocumentId] = useState(false);
  const [customElement, setCustomElement] = useState();
  const handleDelete = async () => {
    // await deleteDoc(doc(db, "projects", deleteDocumentIndex));
    setLoadingAnimation(true);

    handleDocumentData(
      items.filter((item, index) => index !== Number(deleteDocumentIndex)),
    );

    setDeleteDocumentId(false);
    setLoadingAnimation(false);
  };

  const handleDownload = async (path) => {
    setLoadingAnimation(true);
    const storage = getStorage();
    const storageRef = ref(storage, path);
    const link = await getDownloadURL(storageRef);

    const linkInfo = await getMetadata(storageRef);

    await generateDocument(link, linkInfo, async () => {
      const aElement = document.createElement("a");
      aElement.setAttribute("download", `${linkInfo?.customMetadata?.name}`);

      aElement.href = link;
      aElement.setAttribute("target", "_blank");
      aElement.click();
      aElement.remove();
    });

    setLoadingAnimation(false);
  };

  const handleUpload = async (file, index) => {
    setLoadingAnimation(true);
    file = file?.[0] || file;
    if (!file) return;

    if (
      file.type !==
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ) {
      setCustomElement(
        <Snackbar
          autoHideDuration={6000}
          open={true}
          message="The only supported format is .docx"
        />,
      );
      setLoadingAnimation(false);
      setTimeout(() => {
        setCustomElement();
      }, 3000);
      return;
    }

    const currentDocument = items?.[index];

    if (!currentDocument) return;

    const fileRes = uploadFileResumable(
      `documentsCustomizedFiles/${nextPushId()()}`,
      file,
    );
    fileRes.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

        setLoadingAnimation({
          // style: { top: "50%", left: "50%", transform: "translate(-50%,-50%)" },
          variant: "determinate",
          value: progress,
          content: "Uploading...",
        });
      },
      () => {
        setLoadingAnimation(false);
      },
      async () => {
        let documentsTmp = items;

        documentsTmp[index] = {
          ...documentsTmp[index],
          fileUrl: fileRes?.snapshot?.metadata?.fullPath,
        };

        await handleDocumentData(documentsTmp);
        setCustomElement(
          <Snackbar
            autoHideDuration={3000}
            open={true}
            message="File uploaded to document Successfully"
          />,
        );
        setTimeout(() => {
          setCustomElement();
        }, 3000);
        setLoadingAnimation(false);
      },
    );
  };

  const renderItems = items.map((document, index) => {
    const typeName =
        documentTypes?.find((item) => item?.id === document?.documentType)
          ?.name || document?.documentTypeName,
      documentFile =
        document?.fileUrl ||
        document?.documentTypeFileUrl ||
        documentTypes?.find((item) => item?.id === document?.documentType)
          ?.fileUrl;
    return (
      <ListItem key={index}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
        >
          <Grid
            item
            xs={12}
            md={2}
            lg={2}
            sx={{ textOverflow: "ellipsis", overflow: "hidden" }}
            title={typeName}
          >
            {typeName}
            {/* </Button> */}
          </Grid>
          <Grid item xs={12} md={2} lg={2}>
            {document?.dateSent}
          </Grid>

          <Grid item xs={12} md={2} lg={2}>
            {document?.recipient}
          </Grid>

          <Grid item xs={12} md={2} lg={2}>
            {document.dateReceived}
          </Grid>

          <Grid
            item
            xs={12}
            md={2}
            lg={2}
            sx={{ textOverflow: "ellipsis", overflow: "hidden" }}
            title={document?.comments}
          >
            {document?.comments}
          </Grid>

          <Grid item xs={1} md={1} lg={1} sx={{ display: "flex" }}>
            {/* <a href={}> */}
            <IconButton
              onClick={() => handleDownload(`${documentFile}`)}
              title="Download a copy of the Document (any field keys are auto-filled)"
            >
              <Download />
            </IconButton>

            <IconButton
              onClick={(e) =>
                e?.target?.closest("button")?.children?.[0]?.click()
              }
              title="Upload a new version of the Document"
            >
              <input
                id="contained-button-file"
                style={{ display: "none" }}
                type="file"
                accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                onChange={(e) => handleUpload(e.target.files, index)}
              />
              <Upload />
            </IconButton>

            <Divider
              variant="middle"
              sx={{
                background: "#002977",
                width: "1px",
                height: "30px",
                marginBlock: "auto",
              }}
            />

            <IconButton
              onClick={() => setAddDialogOpen(`${index}`)}
              title="Edit the Document"
            >
              <Edit />
            </IconButton>
            <IconButton
              onClick={
                () => setDeleteDocumentId(`${index}`)
                //   handleDelete(project.id, project.customerReference)
              }
              title="Delete the Document"
            >
              <Delete />
            </IconButton>
          </Grid>
        </Grid>
      </ListItem>
    );
  });

  return (
    <div>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
        textAlign={"left"}
        padding={"1rem 1rem 0 1rem"}
        sx={{ display: { xs: "none", md: "flex" } }}
      >
        <Grid item xs={12} md={2} lg={2}>
          <span>Document Type</span>
        </Grid>
        <Grid item xs={12} md={2} lg={2}>
          <span>Date Sent</span>
        </Grid>
        <Grid item xs={12} md={2} lg={2}>
          <span>Recipient</span>
        </Grid>
        <Grid item xs={12} md={2} lg={2}>
          <span>Date Received</span>
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <span>Comments</span>
        </Grid>
      </Grid>

      {renderItems}
      {customElement}

      <ConfirmDialog
        {...{
          open: deleteDocumentIndex,
          title: "Delete Document",
          content: `Are you sure you want to delete this Document? This action cannot be undone`,
          handleClose: () => setDeleteDocumentId(false),
          handleConfirm: handleDelete,
        }}
      />
    </div>
  );
}
