import React, { useState, useEffect, useContext } from "react";
import { Routes, Route, Link, BrowserRouter } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";

import { signOut } from "firebase/auth";

import { Dashboard } from "../pages/Dashboard";
import { Buildings } from "../pages/Buildings";
import { BuildingDetail } from "../pages/BuildingDetail";
import { BuildingNew } from "../pages/BuildingNew";
import { DOB } from "../pages/DOB";
import { ContactDetail } from "../pages/ContactDetail";
import { ContactNew } from "../pages/ContactNew";
import { Contacts } from "../pages/Contacts";
import { Projects } from "../pages/Projects";
import { Settings } from "../pages/Settings";
import { Subscription } from "../pages/Subscription";
import { Profile } from "../pages/Profile";
import { Profiles } from "../pages/Profiles";
import { ProfileDetail } from "../pages/ProfileDetail";
import { ProjectNew } from "../pages/ProjectNew";
import { ProjectDetail } from "../pages/ProjectDetail";
import ToDo from "../pages/ToDo";
import MuiDrawer from "@mui/material/Drawer";
import { Switch } from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import mainLogo from "../images/xpdtr.png";
import AccountMenu from "./AccountMenu";
import BuyNow from "./BuyNow";

import {
  useSubscriptionState,
  useSubscriptionDispatch,
  setPro,
  setStandard,
  setInactive,
} from "../context/SubscriptionContext";

import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";

import IconButton from "@mui/material/IconButton";

import {
  collection,
  query,
  where,
  getDocs,
  or,
  onSnapshot,
  doc,
  limit,
} from "firebase/firestore";
import {
  FolderOpen as ProjectIcon,
  ContactPhone as PeopleIcon,
  Dashboard as DashboardIcon,
  Settings as SettingsIcon,
  Business as BuildingIcon,
  PlaylistAddCheck as TasksIcon,
  LocationCity as DOBIcon,
  Help as SupportIcon,
  Group as GroupsIcon,
  SettingsInputSvideoRounded,
} from "@mui/icons-material";

import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import X from "./x";
import P from "./p";
import D from "./d";
import T from "./t";
import R from "./r";
import { Context } from "context/Wrapper";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {},
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function Layout(props) {
  const context = useContext(Context);

  const { currentUser } = props.auth;
  const { db } = props;
  const theme = useTheme();

  const { displayName, email } = currentUser;

  const SubscriptionDispatch = useSubscriptionDispatch();

  const [subscription, setSubscription] = useState();

  const [showGroup, setShowGroup] = useState(false);

  const [userProfile, setUserProfile] = useState({});

  useEffect(() => {
    if (context?.setState)
      context?.setState({
        userProfile,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(userProfile)]);

  const hasProProduct = (activeProductInfo) => {
    // Check if any product has "isPro" set to true
    return activeProductInfo.some(
      (productInfo) => productInfo.isPro === "true",
    );
  };

  const getActiveProductInfo = (jsonData) => {
    const activeProductInfo = [];

    // Iterate through each subscription in the JSON data
    jsonData.forEach((item) => {
      // Check if the subscription has the status "active"
      if (item.status === "active") {
        // Iterate through each item in the subscription

        // Add product information to the list
        const productInfo = {
          productId: item.price_details?.product_id,
          isPro:
            item.price_details?.custom_data &&
            item.price_details?.custom_data.isPro,
        };
        activeProductInfo.push(productInfo);
      }
    });

    return activeProductInfo;
  };

  /*   const checkIfPro = (currentUser) => {
    // Send email to check.php.
    // check.php must get the list of all users from Paddle, then see if current user is among Pro users.
    const userEmail = email; // Note: 'email' should be defined or passed somewhere before using it.
    const URL =
      process.env.NODE_ENV === "production"
        ? `/check.php?email=${encodeURIComponent(userEmail)}`
        : `/api/check.php?email=${encodeURIComponent(userEmail)}`;

    const PARAMS = {
      method: "GET",
      cache: "default",
    };

    const isJson = (str) => {
      try {
        JSON.parse(str);
      } catch (e) {
        return false;
      }
      return true;
    };

    fetch(URL, PARAMS)
      .then((res) => res.text())
      .then((response) => {
        if (response.indexOf("No customer") === -1 && isJson(response)) {
          let res = JSON.parse(response);
          setSubscription(res);

          const activeProductInfo = getActiveProductInfo(res);

          const isPro = hasProProduct(activeProductInfo);

          if (isPro) {
            setPro(SubscriptionDispatch);
          } else if (activeProductInfo.length > 0) {
            setStandard(SubscriptionDispatch);
          } else {
            setByNowOpen(true);
          }
        } else {
          setByNowOpen(true);
        }
      })
      .catch((error) => console.log(error));
  }; */

  const checkIfPro = (userProfile) => {
    if (userProfile.subscriptionType === "pro") {
      setPro(SubscriptionDispatch);
    } else if (userProfile.subscriptionType === "standard") {
      setStandard(SubscriptionDispatch);
    }

    if (userProfile.subscription?.toLowerCase() === "trial") {
      setByNowOpen(true);
    }

    if (userProfile.subscription?.toLowerCase() === "inactive") {
      setSubscription({ ...subscription, status: "inactive" });
      setInactive(SubscriptionDispatch);
    }
  };

  useEffect(() => {
    if (userProfile.userId) {
      checkIfPro(userProfile);
      // setPro(SubscriptionDispatch); // REMOVE!! IMPORTANT!! TODO!!}
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProfile]);

  const currentSubscription = useSubscriptionState();

  const [buyNowOpen, setByNowOpen] = useState(false);

  const toggleBuyNow = () => {
    setByNowOpen(!buyNowOpen);
  };

  const [open, setOpen] = React.useState(false);

  // const [buildings, setBuildings] = useState([]);

  // useEffect(() => {
  //   let q;
  //   if (showGroup) {
  //     q = query(
  //       collection(db, "buildings"),
  //       or(
  //         where("groupId", "==", userProfile.groupId),
  //         where("userId", "==", currentUser.uid)
  //       )
  //     );
  //   } else {
  //     q = query(
  //       collection(db, "buildings"),
  //       where("userId", "==", currentUser.uid)
  //     );
  //   }

  //   const unsubscribe = onSnapshot(q, (querySnapshot) => {
  //     const newBuildings = querySnapshot.docs.map((doc) => {
  //       const data = doc.data();
  //       return {
  //         ...data,
  //         id: doc.id,
  //         fullAddress: data.houseNumber + " " + data.streetName,
  //       };
  //     });

  //     setBuildings(newBuildings);
  //   });
  // }, [showGroup]);

  function oneMonthFromToday() {
    const today = new Date();
    let month = today.getMonth() + 1; // JavaScript months are 0-indexed
    let year = today.getFullYear();

    if (month === 12) {
      // If current month is December, move to January of next year
      month = 0; // Set to January (0-indexed)
      year += 1;
    }

    // Construct a new date object for the next month
    // Using the same day can lead to issues when the current day doesn't exist in the next month
    // To avoid this, set the day to 1 and then adjust the month
    const nextMonthDate = new Date(year, month, 1);

    // Adjust back to the same day as today, or the closest day in the next month
    // This handles cases like January 31st -> February 28th (or 29th in a leap year)
    const daysInNextMonth = new Date(year, month + 1, 0).getDate();
    const day = Math.min(today.getDate(), daysInNextMonth);

    nextMonthDate.setDate(day);

    return nextMonthDate;
  }

  useEffect(() => {
    const q2 = query(
      collection(db, "profiles"),
      where("userId", "==", currentUser.uid),
      limit(1),
    );
    const q = query(doc(db, "profiles", currentUser.uid));

    let searchProfile = {};

    getDocs(q2).then((querySnapshot) => {
      if (!querySnapshot.empty) {
        const doc = querySnapshot.docs[0];
        if (doc.data().userId !== doc.id) {
          searchProfile = {
            ...doc.data(),
            id: doc.id,
          };
          db.collection("profiles").doc(searchProfile.id).delete();
        }
      }
    });

    const unsubscribe = onSnapshot(q, async (querySnapshot) => {
      // const profiles = querySnapshot.docs.map((doc) => ({
      //   data: doc.data(),
      //   id: doc.id,
      // }));

      const dateOneMonthFromToday = oneMonthFromToday();
      // Check if profile exists

      if (querySnapshot.data()?.email) {
        // Merge the found profile fields int

        const profile = {
          data: querySnapshot.data(),
          id: querySnapshot.id,
        };

        const {
          email,
          phone,
          groupId,
          subscription,
          subscriptionType,
          expirationDate,
          autonumber,
          projectNumberFormat,
        } = profile.data;

        if (groupId?.length > 0) localStorage.setItem("groupId", groupId);
        else localStorage.removeItem("groupId");

        setUserProfile({
          ...userProfile,
          userId: currentUser.uid,
          uid: currentUser.uid,
          displayName: currentUser.displayName,
          userName: currentUser.displayName,
          profileId: profile.id,
          creationTime:
            querySnapshot?._document?.createTime?.timestamp?.seconds * 1000,
          email,
          phone,
          subscription,
          subscriptionType,
          expirationDate,
          autonumber: autonumber,
          projectNumberFormat: projectNumberFormat,
          groupId: groupId ? groupId : currentUser.uid,
          isPro: subscriptionType === "pro",
        });

        if (searchProfile?.email) {
          const searchProfileTmp = {
            ...searchProfile,
          };

          delete searchProfileTmp.id;

          await db
            .collection("profiles")
            .doc(currentUser.uid)
            .update({ searchProfileTmp, searchProfileUpdate: true })
            .catch((error) => {
              console.error("Error adding document: ", error);
            });

          db.collection("profiles").doc(searchProfile.id).delete();
        }
      } else {
        // Create a new profile for this user
        await db
          .collection("profiles")
          .doc(currentUser.uid)
          .set({
            email: currentUser.email,
            userId: currentUser.uid,
            subscription: "trial",
            subscriptionType: "standard",
            expirationDate: `${dateOneMonthFromToday}`,
            groupId: currentUser.uid,
          })
          .catch((error) => {
            console.error("Error adding document: ", error);
          });
      }
    });

    return () => unsubscribe(); // Clean up the subscription on component unmount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, JSON.stringify(userProfile)]);

  // const [contacts, setContacts] = useState([]);

  // useEffect(() => {
  //   let q;
  //   if (showGroup) {
  //     q = query(
  //       collection(db, "contacts"),

  //       or(
  //         where("groupId", "==", userProfile.groupId),
  //         where("userId", "==", currentUser.uid)
  //       )
  //     );
  //   } else {
  //     q = query(
  //       collection(db, "contacts"),

  //       where("userId", "==", currentUser.uid)
  //     );
  //   }

  //   const unsubscribe = onSnapshot(q, (querySnapshot) => {
  //     const newcontacts = querySnapshot.docs.map((doc) => {
  //       const data = doc.data();
  //       return { ...data, id: doc.id };
  //     });

  //     setContacts(newcontacts);
  //   });
  // }, [showGroup]);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleSignOut = () => {
    signOut(props.auth)
      .then(() => {
        // Sign-out successful.
      })
      .catch((error) => {
        // An error happened.
      });
  };

  const handleSetGroup = (e) => {
    setShowGroup(e.target.checked);
    context.setState({ showAllDataFlag: e.target.checked });
  };

  return (
    <BrowserRouter>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="fixed" open={open} elevation={0}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              edge="start"
              sx={{
                marginRight: "28px",
                transition: "all .25s",
                ...(open && {
                  marginLeft: "-70px",
                  transform: "rotate(-90deg)",
                }),
              }}
            >
              <img src={mainLogo} className={"logoClass"} alt="expediter" />
            </IconButton>

            <IconButton
              style={{ marginLeft: "-12px" }}
              href="//www.xpdtr.com"
              target="_blank"
              sx={{ display: { xs: "none", sm: "block" } }}
            >
              <X style={{ height: "40px", width: "40px" }} />
              <P style={{ height: "40px", width: "40px" }} />
              <D style={{ height: "40px", width: "40px" }} />
              <T style={{ height: "40px", width: "40px" }} />
              <R style={{ height: "40px", width: "40px" }} />
            </IconButton>
            <Typography
              variant="subtitle2"
              noWrap
              component="div"
              marginLeft={"auto"}
              sx={{ display: { xs: "none", sm: "block" } }}
            >
              XPDTR (ek-spə-ˌdī-tər) - NYC construction and real estate
              paperwork, simplified.
            </Typography>
            <Typography
              variant="subtitle2"
              noWrap
              component="div"
              marginLeft={"10px"}
              sx={{ display: { xs: "block", sm: "none" } }}
            >
              XPDTR
            </Typography>
            {currentSubscription?.isPro && !currentSubscription.isInactive && (
              <>
                <Typography
                  variant="subtitle2"
                  noWrap
                  component="div"
                  marginLeft={"30px"}
                  color={"tertiary"}
                >
                  My data
                </Typography>
                <Switch
                  checked={showGroup}
                  onChange={handleSetGroup}
                  color="secondary"
                />
                <Typography
                  variant="subtitle2"
                  noWrap
                  component="div"
                  marginRight={"30px"}
                  color={"secondary"}
                >
                  All data
                </Typography>
              </>
            )}

            <AccountMenu
              handleSignOut={handleSignOut}
              subscription={subscription}
            />
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open} className={"sidebarDrawer"}>
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}></IconButton>
          </DrawerHeader>
          <List className={"sideL"} style={{ paddingTop: 0 }}>
            <ListItem button component={Link} to="/home">
              <ListItemIcon>
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText primary={"Home"} />
            </ListItem>
            <ListItem button component={Link} to="/buildings">
              <ListItemIcon>
                <BuildingIcon />
              </ListItemIcon>
              <ListItemText primary={"Buildings"} />
            </ListItem>{" "}
            <ListItem button key={"Projects"} component={Link} to="/projects">
              <ListItemIcon>
                <ProjectIcon />
              </ListItemIcon>
              <ListItemText primary={"Projects"} />
            </ListItem>
            <ListItem button key={"Contacts"} component={Link} to="/contacts">
              <ListItemIcon>
                <GroupsIcon />
              </ListItemIcon>
              <ListItemText primary={"Contacts"} />
            </ListItem>
            <ListItem button key={"Tasks"} component={Link} to="/todo">
              <ListItemIcon>
                <TasksIcon />
              </ListItemIcon>
              <ListItemText primary={"Tasks"} />
            </ListItem>
            {(currentUser.email === "hossamamin80@yahoo.ca" ||
              currentUser.email === "ahmedelswerkey@gmail.com" ||
              currentUser.email === "seamuffin@gmail.com") && (
              <ListItem button key={"Profiles"} component={Link} to="/profiles">
                <ListItemIcon>
                  <GroupsIcon />
                </ListItemIcon>
                <ListItemText primary={"User Profiles"} />
              </ListItem>
            )}
          </List>
        </Drawer>
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <DrawerHeader />

          <Routes>
            <Route
              path="/home"
              element={
                <Dashboard
                  user={currentUser}
                  isPro={currentSubscription.isPro}
                  isInactive={currentSubscription.isInactive}
                />
              }
            />
            <Route
              path="/"
              element={
                <Dashboard
                  user={currentUser}
                  isPro={currentSubscription.isPro}
                  isInactive={currentSubscription.isInactive}
                />
              }
            />
            <Route
              path="/buildings"
              element={
                <Buildings isInactive={currentSubscription.isInactive} />
              }
            />
            <Route
              path="/buildings/details/:buildingId"
              element={
                <BuildingDetail isInactive={currentSubscription.isInactive} />
              }
            />
            <Route
              path="/buildings/new/"
              element={
                <BuildingNew isInactive={currentSubscription.isInactive} />
              }
            />
            <Route
              path="/contacts"
              element={<Contacts isInactive={currentSubscription.isInactive} />}
            />
            <Route
              path="/profiles"
              element={<Profiles isInactive={currentSubscription.isInactive} />}
            />
            <Route
              path="/contacts/details/:contactId"
              element={
                <ContactDetail isInactive={currentSubscription.isInactive} />
              }
            />
            <Route
              path="/userProfiles/details/:contactId"
              element={
                <ProfileDetail isInactive={currentSubscription.isInactive} />
              }
            />
            <Route
              path="/contacts/new/"
              element={
                <ContactNew isInactive={currentSubscription.isInactive} />
              }
            />
            <Route
              path="/projects"
              element={<Projects isInactive={currentSubscription.isInactive} />}
            />
            <Route
              path="/subscription"
              element={<Subscription subscription={userProfile} />}
            />
            <Route path="/settings" element={<Settings />} />
            <Route path="/profile" element={<Profile />} />
            <Route
              path="/projects/details/:projectId"
              element={
                <ProjectDetail isInactive={currentSubscription.isInactive} />
              }
            />
            <Route
              path="/projects/new/"
              element={
                <ProjectNew isInactive={currentSubscription.isInactive} />
              }
            />
            <Route
              path="/todo"
              element={<ToDo isInactive={currentSubscription.isInactive} />}
            />
            <Route path="/dob" element={<DOB />} />
            {/*
          <Route path="/app/ui/settings" component={Settings} />
          <Route path="/app/ui/groups" component={Groups} /> */}
          </Routes>
        </Box>
      </Box>
      <BuyNow open={buyNowOpen} toggleBuyNow={toggleBuyNow} email={email} />
    </BrowserRouter>
  );
}
