import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Typography,
  TextField,
  Grid,
  Box,
  Tab,
  Tabs,
  Fab,
  MenuItem,
  Snackbar,
} from "@mui/material";
import { useSubscriptionState } from "../context/SubscriptionContext";

import MuiAlert from "@mui/material/Alert";

import Status from "./Status";
import { Forms } from "../components/Forms";
import Notes from "../components/Notes";

import { SaveCloseDialog } from "../components/SaveCloseDialog";
import { Save, Close } from "@mui/icons-material";

import { doc, getDoc, or, setDoc, where } from "firebase/firestore";
import { db } from "config/config";
import { Context } from "context/Wrapper";
import { useFetchCollectionDocs } from "apis/dataSnapshots";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3, pl: 0, pr: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export const BuildingDetail = (props) => {
  const context = useContext(Context);

  const { groupId, isPro, userId } = context?.state?.userProfile || {};
  const user = context?.state?.userProfile || {},
    { showAllDataFlag } = context?.state || {};

  const { buildingId } = useParams();

  const currentSubscription = useSubscriptionState();

  const contacts = useFetchCollectionDocs({
    collectionName: "contacts",
    conditions:
      userId && groupId && isPro && showAllDataFlag
        ? [or(where("groupId", "==", groupId), where("userId", "==", userId))]
        : userId
          ? [where("userId", "==", userId)]
          : [],
  });

  // const { groupId } = props;

  const navigate = useNavigate();

  const [buildingDetail, setBuildingDetail] = useState();

  const [ownerDetails, setOwnerDetails] = React.useState(null);

  useEffect(() => {
    async function getBuildings() {
      const buildingRef = doc(db, "buildings", buildingId);
      const initialBuildingDetail = await getDoc(buildingRef);
      setBuildingDetail(initialBuildingDetail.data());
    }
    getBuildings();
  }, [buildingId]);

  const [isDirty, setIsDirty] = React.useState(false);

  const [tab, setTab] = React.useState(0);

  const [open, setOpen] = React.useState(false);

  const [dialogOpen, setDialogOpen] = React.useState(false);

  const [failureOpen, setOpenFailure] = React.useState(false);

  const openSuccess = () => {
    setOpen(true);
  };

  const openFailure = () => {
    setOpenFailure(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleFailureClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenFailure(false);
  };

  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };

  const handleChange = (e) => {
    let currentBuildingDetail = buildingDetail;
    currentBuildingDetail[e.target.name] = e.target.value;
    setIsDirty(true);
    setBuildingDetail({ ...currentBuildingDetail });
  };

  const handleChangeNotes = (notes) => {
    let currentBuildingDetail = buildingDetail;
    setBuildingDetail({ ...currentBuildingDetail, notes: notes });
  };

  const onSubmit = () => {
    const thisBuilding = doc(db, "buildings", buildingId);
    setDoc(thisBuilding, { ...buildingDetail }, { merge: true })
      .then(() => {
        setIsDirty(false);
        openSuccess();
      })
      .catch((error) => {
        openFailure();
      });
  };

  const saveAndClose = () => {
    onSubmit();
    navigate("/buildings");
  };

  const discardAndClose = () => {
    navigate("/buildings");
  };

  const handleCloseBuilding = () => {
    if (isDirty) {
      setDialogOpen(true);
      return;
    }
    navigate("/buildings");
  };

  React.useEffect(() => {
    if (buildingDetail && buildingDetail.owner) {
      const fetchOwnerDetails = async () => {
        const ownerRef = doc(db, "contacts", buildingDetail.owner);
        const initialOwnerDetail = await getDoc(ownerRef);
        const owner = initialOwnerDetail.data();
        setOwnerDetails(owner);
      };
      fetchOwnerDetails();
    }
  }, [buildingDetail]);

  if (!buildingDetail) return null;

  const hasRequiredFields = () => {
    return (
      buildingDetail.customerReference &&
      buildingDetail.houseNumber &&
      buildingDetail.streetName
    );
  };

  return (
    <>
      <Typography
        sx={{
          textTransform: "uppercase",
          /*    backgroundColor: "#f89d08",
          padding: "1rem", */
        }}
        align="left"
        variant="h4"
      >
        {buildingDetail.customerReference}
      </Typography>
      <Box sx={{ borderBottom: 1, borderColor: "divider", paddingLeft: 0 }}>
        <Tabs
          value={tab}
          onChange={handleChangeTab}
          aria-label="basic tabs example"
        >
          <Tab label="Details" />
          <Tab label="DOB status" />
          <Tab label="Notes" />
          <Tab label="Forms" />
        </Tabs>
      </Box>
      <TabPanel value={tab} index={0}>
        <form name="buildingForm" id="buildingForm">
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={4}>
              <TextField
                label="Name"
                variant="outlined"
                name="customerReference"
                value={buildingDetail.customerReference}
                onChange={handleChange}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <TextField
                label={"Borough"}
                name="borough"
                select
                onChange={handleChange}
                value={buildingDetail.borough}
                fullWidth
              >
                <MenuItem value={"bk"}>Brooklyn</MenuItem>
                <MenuItem value={"mh"}>Manhattan</MenuItem>
                <MenuItem value={"bx"}>Bronx</MenuItem>
                <MenuItem value={"qn"}>Queens</MenuItem>
                <MenuItem value={"si"}>Staten Island</MenuItem>
              </TextField>
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              <TextField
                label="House number"
                variant="outlined"
                name="houseNumber"
                value={buildingDetail.houseNumber}
                onChange={handleChange}
                fullWidth
                required
              />
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              <TextField
                label="Street name"
                variant="outlined"
                name="streetName"
                value={buildingDetail.streetName}
                onChange={handleChange}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <TextField
                label="City"
                variant="outlined"
                name="city"
                value={buildingDetail.city}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <TextField
                label="State"
                variant="outlined"
                name="state"
                value={buildingDetail.state}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <TextField
                label="ZIP"
                variant="outlined"
                name="ZIP"
                value={buildingDetail.ZIP}
                onChange={handleChange}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              <TextField
                label="BIN"
                variant="outlined"
                name="BIN"
                value={buildingDetail.BIN}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <TextField
                label="Block"
                variant="outlined"
                name="block"
                value={buildingDetail.block}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <TextField
                label="Lot"
                variant="outlined"
                name="lot"
                value={buildingDetail.lot}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <TextField
                label="CB No."
                variant="outlined"
                name="cb"
                value={buildingDetail.cb}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <TextField
                label={"Owner"}
                name="owner"
                select
                onChange={handleChange}
                value={buildingDetail.owner}
                fullWidth
              >
                {contacts.map((contact) => (
                  <MenuItem value={contact.id}>{contact.fullName}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <TextField
                label={"Tenant"}
                name="tenant"
                select
                onChange={handleChange}
                value={buildingDetail.tenant}
                fullWidth
              >
                {contacts.map((contact) => (
                  <MenuItem value={contact.id}>{contact.fullName}</MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </form>
        <Box
          sx={{
            position: "fixed",
            bottom: "1rem",
            right: "1rem",
            "& > :not(style)": { m: 1 },
          }}
        >
          {!props.isInactive && (
            <Fab
              color="primary"
              aria-label="add"
              variant="extended"
              onClick={onSubmit}
              disabled={(!isDirty && !hasRequiredFields()) || props.isInactive}
            >
              <Save sx={{ mr: 1 }} />
              Save
            </Fab>
          )}
          <Fab
            color="secondary"
            aria-label="add"
            variant="extended"
            onClick={handleCloseBuilding}
          >
            <Close sx={{ mr: 1 }} />
            Close
          </Fab>
        </Box>

        <SaveCloseDialog
          open={dialogOpen}
          saveAndClose={saveAndClose}
          discardAndClose={discardAndClose}
          setDialogOpen={setDialogOpen}
          isInactive={props.isInactive}
        />
      </TabPanel>
      <TabPanel value={tab} index={1}>
        {!props.isInactive ? (
          <div style={{ height: "calc(100vh - 230px)" }}>
            <Status currentBuilding={{ ...buildingDetail }} />
          </div>
        ) : (
          <div style={{ height: "calc(100vh - 230px)" }}>
            This feature is available with an active subscription.
          </div>
        )}
      </TabPanel>
      <TabPanel value={tab} index={2}>
        <Notes
          notes={buildingDetail.notes}
          handleChangeNotes={handleChangeNotes}
          onSubmit={onSubmit}
        />
      </TabPanel>
      <TabPanel value={tab} index={3}>
        <Forms
          buildingId={buildingId}
          buildingDetails={buildingDetail}
          ownerDetails={ownerDetails}
          userId={buildingDetail.userId}
          db={db}
          groupId={groupId}
          isInactive={props.isInactive}
        />
      </TabPanel>
      <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Building saved successfully!
        </Alert>
      </Snackbar>
      <Snackbar
        open={failureOpen}
        autoHideDuration={2000}
        onClose={handleFailureClose}
      >
        <Alert
          onClose={handleFailureClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          Something went wrong!
        </Alert>
      </Snackbar>
    </>
  );
};
